import styled from 'styled-components'
import { FONTS } from '~/styled/components/typography'

export const BannerTitle = styled.div`
  * {
    font-family: ${FONTS.Cormorant} !important;
    margin-bottom: 10px !important;
  }
`

export const BannerFooterTextBlock = styled.div`
  display: contents;

  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    * {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
    }
    h4 {
      font-size: 14px;
      margin-bottom: 0 !important;
    }
    p {
      font-size: 10px;
      margin-bottom: 0 !important;
    }
  }
`

export const BannerWrapper = styled.div<{ $full?: boolean }>`
  display: contents;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* position: absolute;
    top: 0;
    left: 0;
    z-index: -1; */
  }
`
