/** @jsx jsx */
import { ICMSBanner } from './types'
import { Link } from 'gatsby'
import ImageWrapper from '~/components/image-wrapper'
import { jsx, Flex, Heading, Box, Image, Text } from 'theme-ui'
import BlockTextRender from '../pageBuilder/components/BlockTextRender'
import { BannerFooterTextBlock, BannerTitle, BannerWrapper } from './styles'
import { useEffect, useMemo, useState } from 'react'
import { TGridVariant } from '~/containers/collection-products/components/types'

interface BannerInjectComponentProps {
  banner: ICMSBanner
  isRugs?: boolean
  gridLayout: TGridVariant
}

type Ratios = {
  [key in TGridVariant]: number
}

const RATIOS: Ratios = {
  'layout.template-rugs': 583 / 466,
  'layout.template-antique-rugs': 379 / 541,
  'layout.template-default': 1,
  'layout.template-quickship': 1,
  'layout.template-inStock': 1,
  'layout.template-lastChance': 1,
  'layout.template-furniture': 1,
}
export const BannerInjectComponent = ({ banner, gridLayout }: BannerInjectComponentProps) => {
  const { title = {}, description = {} } = banner

  const fullVersion = useMemo(() => {
    return !Object.keys(title).length && !Object.keys(description).length
  }, [banner])

  const ratio = useMemo(() => {
    return RATIOS[gridLayout]
  }, [fullVersion])

  return (
    <BannerWrapper>
      <ImageWrapper ratio={ratio}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {banner.image?.url ? (
            <Link
              to={banner.link || '#'}
              style={{
                position: 'relative',
                width: '100%',
                height: fullVersion ? '100%' : '75%',
              }}
            >
              <Image
                className={`${banner.handle}`}
                src={banner.image.url}
                loading="lazy"
                variant="FULLSCREEN"
                alt={banner.name}
              />
            </Link>
          ) : null}
          {!fullVersion ? (
            <BannerFooterTextBlock>
              <Box
                sx={{
                  p: [2, 4, 8, 16],
                  background: banner.backgroundColor || 'none',
                  color: banner.textColor || 'text',
                  textAlign: 'center',
                  width: '100%',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '25%',
                }}
              >
                {banner?.title.length ? (
                  <BannerTitle>
                    <BlockTextRender content={banner.title} />{' '}
                  </BannerTitle>
                ) : null}
                {banner?.description.length ? (
                  <BlockTextRender content={banner.description} />
                ) : null}
              </Box>
            </BannerFooterTextBlock>
          ) : null}
        </Box>
      </ImageWrapper>
    </BannerWrapper>
  )
}

export const defaultRenderBanner = (banner: ICMSBanner, key: string) => {
  return <BannerInjectComponent key={key} banner={banner} />
}
